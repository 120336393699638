<template>
	<div
		ref="header"
		class="heatmap-header"
		:style="headerStyle"
	>
		<div class="heatmap-header__controls">
			<ZyroButton
				v-qa="'builder-heatmap-close'"
				theme="primary"
				size="xs"
				color="blue"
				icon="close-sm"
				icon-dimesnions="16px"
				class="heatmap-header__close"
				@click="closeHeatmap"
			/>
			<div class="heatmap-header__label z-subheading">
				{{ $t('builder.AIHeatmapLabel') }}
			</div>
		</div>
		<div class="heatmap-header__text z-body-small">
			<ZyroSvg
				class="heatmap-header__more-icon"
				name="info-circle"
			/>
			{{ $t('builder.AIHeatmapText') }}
			<a
				:href="moreHref"
				target="_blank"
			>
				{{ $t('builder.AIHeatmapMore') }}
			</a>
		</div>
	</div>
</template>

<script>
import { WWW_REDIRECT_PATHS } from '@/constants';
import {
	mapStateGui,
	mapActionsGui,
	CLOSE_HEATMAP,
} from '@/store/builder/gui';
import { useRedirects } from '@/use/useRedirects';

const { AI_HEATMAP } = WWW_REDIRECT_PATHS;

export default {
	setup() {
		const { getRedirectToWWWUrl } = useRedirects();

		const moreHref = getRedirectToWWWUrl({ path: AI_HEATMAP });

		return { moreHref };
	},
	data() {
		return { headerHeight: null };
	},
	computed: {
		...mapStateGui(['isHeatmapOpen']),
		headerStyle: ({
			headerHeight,
			headerTop,
			isHeatmapOpen,
		}) => headerHeight && headerTop && {
			top: `${headerTop}px`,
			height: isHeatmapOpen ? `${headerHeight}px` : 0,
		},
	},
	mounted() {
		const {
			top,
			height,
		} = this.$refs.header.getBoundingClientRect();

		this.headerHeight = height;
		this.headerTop = top;
	},
	methods: mapActionsGui({ closeHeatmap: CLOSE_HEATMAP }),
};
</script>

<style lang="scss" scoped>
.heatmap-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 48px;
	padding: 0 6px 0 ($side-toolbar-width+6px);

	&__controls {
		display: flex;
		align-items: center;
	}

	&__close {
		margin-right: 8px;
	}

	&__label {
		margin-right: 8px;
	}

	&__text {
		padding-right: 32px;
		text-align: center;
	}

	&__more-icon {
		width: 16px;
		height: 16px;
		vertical-align: middle;
	}
}
</style>
