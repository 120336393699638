<template>
	<ZyroButton
		:disabled="isSiteBeingPublished"
		class="publish-button"
		theme="header"
		color="red"
		@click="handlePublish"
	>
		<div
			class="publish-button__content"
			data-qa="builder-header-text-publishsite"
		>
			<Transition
				v-if="isSiteBeingPublished"
				name="fade"
			>
				<ZyroLoader
					class="publish-button__loader"
					:style="{
						'--size': '20px',
						'--weight': '4px',
						'--border-color': 'var(--accent-two-light)',
						'--color': 'var(--light)'
					}"
				/>
			</Transition>
			{{ buttonText }}
		</div>
	</ZyroButton>
</template>

<script>
import {
	mapMutations,
	mapState,
	mapActions,
} from 'vuex';

import { REDIRECT_PARAM_VALUES } from '@/constants';
import {
	mapActionsGui,
	OPEN_MODAL,
} from '@/store/builder/gui';
import { useRedirects } from '@/use/useRedirects';

export default {
	setup() {
		const { redirectToWWWSignUp } = useRedirects();

		return { redirectToWWWSignUp };
	},
	data() {
		return { isBeingUpdated: false };
	},
	computed: {
		...mapState('user', [
			'user',
			'isUserDesigner',
		]),
		...mapState('blog', ['isBlogEditMode']),
		...mapState(['domain']),
		...mapState('gui', ['isSiteBeingPublished']),
		buttonText() {
			if (this.isUserDesigner) {
				return this.$i18n.t('builder.header.publishTemplate');
			}

			if (this.isSiteBeingPublished) {
				return this.$i18n.t('builder.header.publishing');
			}

			if (this.isBlogEditMode) {
				return this.$i18n.t('common.publishPost');
			}

			return this.user && this.domain ? this.$i18n.t('builder.header.updateWebsite') : this.$i18n.t('builder.header.publishWebsite');
		},
	},
	watch: {
		async isSiteBeingPublished() {
			if (this.isSiteBeingPublished) {
				try {
					await this.saveWebsite();
				} catch {
					// TODO: error handling
				}

				await (this.isBeingUpdated ? this.updateWebsite() : this.publishWebsite(this.domain));
				this.toggleSiteBeingPublished();
			}
		},
	},
	mounted() {
		this.handleQueryOptions();
	},
	methods: {
		...mapMutations('gui', ['toggleSiteBeingPublished']),
		...mapMutations('user', ['setBeingRedirectedToSignup']),
		...mapActions([
			'updateWebsite',
			'saveWebsite',
			'publishWebsite',
		]),
		...mapActions('gui', ['setEditMode']),
		...mapActionsGui({ openModal: OPEN_MODAL }),
		handleQueryOptions() {
			if (this.$route.query.modal === 'publish' && this.user) {
				this.handlePublish();
			}
		},
		async handlePublish() {
			this.setEditMode(false);
			// Textbox watches editMode value, so wait for next tick to get up-to-date JSON value
			await this.$nextTick();
			// Publish for not logged in user
			if (!this.user) {
				this.redirectToWWWSignUp({ returnTo: REDIRECT_PARAM_VALUES.RETURN_PUBLISH });
			}

			if (this.isUserDesigner) {
				this.openModal({ name: 'DesignerBeforePublishModal' });

				return;
			}

			// Normal publish flow
			if (this.domain) {
				this.isBeingUpdated = true;
				this.toggleSiteBeingPublished();

				return;
			}

			this.openModal({ name: 'PublishModal' });
		},
	},
};
</script>

<style scoped lang="scss">
.publish-button {
	flex-shrink: 0;

	&__content {
		display: flex;
		align-items: center;
		white-space: nowrap;
	}

	&__loader:not(.s) {
		margin-right: 8px;
	}
}
</style>
