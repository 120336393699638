<template>
	<div>
		<BuilderHeaderPrimary class="page-layout__header page-layout__header--sticky" />
		<BuilderHeatmapHeader class="page-layout__header-heatmap" />
		<BuilderRoot class="page-layout" />
	</div>
</template>

<script>
import {
	mapState,
	mapGetters,
	mapActions,
} from 'vuex';

import BuilderHeatmapHeader from '@/components/builder-view/components/BuilderHeatmapHeader.vue';
import BuilderHeaderPrimary from '@/components/builder-view/headers/BuilderHeaderPrimary.vue';
import BuilderRoot from '@/components/builder-view/views/BuilderRoot/BuilderRoot.vue';
import {
	mapStateGui,
	mapActionsGui,
	CLOSE_SIDEBAR,
} from '@/store/builder/gui';

export default {
	components: {
		BuilderRoot,
		BuilderHeaderPrimary,
		BuilderHeatmapHeader,
	},
	/**
	 * Set currentPage to mostRecentBuilderPageId, if it was set. This returns to the same page after
	 * navigating elsewhere in the editor
	 * (currentPageId can get unset after preview/blog mode to other)
	 */
	beforeRouteEnter(to, from, next) {
		next((vm) => {
			if (vm.mostRecentBuilderPageId) {
				vm.changePage({ pageId: vm.mostRecentBuilderPageId });
				vm.setMostRecentBuilderPage({ pageId: '' });
			}
		});
	},
	beforeRouteLeave(to, from, next) {
		this.setEditMode(false);
		this.setMostRecentBuilderPage({ pageId: this.currentPageId });
		next();
	},
	computed: {
		...mapStateGui([
			'isSidebarOpen',
			'isMobileScreen',
		]),
		...mapState('pages', [
			'mostRecentBuilderPageId',
			'currentPageId',
		]),
		...mapGetters('pages', ['currentPage']),
	},
	/*
	 * When navigating from blogEditMode not via builder, the blogPageId keeps set
	 * For that reason, blog page content gets loaded in builder. This fixes it.
	 */
	beforeMount() {
		if (this.currentPage.type !== 'default') {
			this.changePage();
		}
	},
	created() {
		if (this.isMobileScreen && this.isSidebarOpen) {
			this.closeSidebar();
		}
	},
	methods: {
		...mapActionsGui({ closeSidebar: CLOSE_SIDEBAR }),
		...mapActions('gui', ['setEditMode']),
		...mapActions('pages', [
			'changePage',
			'setMostRecentBuilderPage',
		]),
	},
};
</script>
<style lang="scss" scoped>
@import './PageLayout';
</style>
