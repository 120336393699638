<template>
	<ZyroTooltip content-position="fixed">
		<template #trigger>
			<ZyroButton
				id="saveWebsiteButton"
				class="save-tooltip-trigger"
				data-qa="builder-header-btn-saved"
				theme="header"
				color="white"
				icon-left="check-circle-blue"
				icon-only-up-to-media="header-desktop"
			>
				{{ $t('common.saved') }}
			</ZyroButton>
		</template>

		<div
			v-if="!user"
			v-qa="'builder-header-save-button-no-user'"
			class="save-tooltip"
		>
			<div class="save-tooltip__title z-subheading">
				{{ $t('common.saveYourChanges') }}
			</div>
			<ZyroSvg
				class="save-tooltip__image"
				name="dont-leave"
			/>
			<div class="save-tooltip__description z-body-small">
				{{ $t('builder.saveButtonPopup.noUser.description') }}
			</div>
			<ZyroButton
				class="save-tooltip__button"
				theme="primary"
				@click="redirectToWWWSignUp"
			>
				{{ $t('common.joinZyro') }}
			</ZyroButton>
			<ZyroButton
				class="save-tooltip__button"
				theme="outline"
				@click="redirectToWWWSignIn"
			>
				{{ $t('common.signIn') }}
			</ZyroButton>
		</div>
		<div
			v-else
			v-qa="'builder-header-save-button'"
			class="save-tooltip"
		>
			<div class="save-tooltip__title z-subheading">
				{{ $t('builder.saveButtonPopup.user.title') }}
			</div>
			<div class="save-tooltip__description z-body-small">
				<ZyroSvg name="auto-save" />
			</div>
			<div class="save-tooltip__description z-body-small">
				{{ $t('builder.saveButtonPopup.user.description') }}
			</div>
		</div>
	</ZyroTooltip>
</template>

<script>
import { mapState } from 'vuex';

import { useRedirects } from '@/use/useRedirects';

export default {
	setup() {
		const {
			redirectToWWWSignUp,
			redirectToWWWSignIn,
		} = useRedirects();

		return {
			redirectToWWWSignUp,
			redirectToWWWSignIn,
		};
	},
	computed: mapState('user', ['user']),
};
</script>

<style lang="scss" scoped>
.save-tooltip {
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 251px;

	&-trigger:not(.s) {
		&:hover,
		&:focus {
			color: $accent-two;
		}
	}

	&__title {
		display: flex;
		margin: 12px 0 24px 0;
	}

	&__description {
		max-width: 220px;
		margin-bottom: 24px;
		text-align: center;
	}

	&__image {
		margin-bottom: 24px;
	}

	&__button {
		width: 100%;
		margin-bottom: 8px;
	}
}
</style>
