<template functional>
	<ZyroButton
		class="logo"
		theme="header"
		color="white"
		v-bind="data.attrs"
		:to="props.to"
		:href="props.href"
		:title="props.title"
	>
		<!-- logo - on desktop -->
		<ZyroSvg
			class="logo__img show-mobile-up"
			data-qa="builder-header-img-zyrologo"
			name="logo"
		/>

		<!-- back arrow - on mobile -->
		<ZyroSvg
			class="show-up-to-mobile"
			name="chevron-left-small"
		/>
	</ZyroButton>
</template>

<script>
export default {
	props: {
		to: {
			type: [
				Object,
				String,
			],
			default: undefined,
		},
		href: {
			type: String,
			default: '',
		},
		title: {
			type: String,
			required: true,
		},
	},
};
</script>

<style lang="scss" scoped>
.logo {
	&__inner {
		display: flex;
		align-items: center;
	}

	&__img {
		margin: 0 3px 0 9px;
	}
}
</style>
