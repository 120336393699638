<template>
	<div
		ref="builderHeader"
		class="builder-header"
	>
		<div class="builder-header__item">
			<ZyroLogo
				:href="logoUrl"
				:title="user ? $t('common.mySites') : $t('common.templates')"
			/>
		</div>

		<!-- Right side -->
		<div class="builder-header__item">
			<!-- Mobile/Desktop view toggle button -->
			<div class="builder-header__separator show-tablet-up" />
			<BuilderHeaderScreenToggleButton class="show-tablet-up" />

			<!-- Undo button -->
			<div class="builder-header__separator show-tablet-up" />
			<ZyroButton
				:disabled="isUndoDisabled"
				data-qa="builderheader-menuitem-undo"
				class="show-tablet-up"
				theme="header"
				color="white"
				icon="arrow-back"
				:title="$t('common.undo')"
				@click="undoWebsite"
			/>

			<!-- Redo button -->
			<div class="builder-header__separator show-tablet-up" />
			<ZyroButton
				:disabled="isRedoDisabled"
				data-qa="builderheader-menuitem-redo"
				class="show-tablet-up"
				theme="header"
				color="white"
				icon="arrow-forward"
				:title="$t('common.redo')"
				@click="redoWebsite"
			/>

			<!-- Save button -->
			<div class="builder-header__separator show-tablet-up" />
			<BuilderHeaderSaveButton class="show-tablet-up" />

			<!-- Preview button -->
			<div class="builder-header__separator" />
			<ZyroButton
				data-qa="builder-header-btn-preview"
				theme="header"
				color="white"
				@click="$router.push({ name: PREVIEW_ROUTE })"
			>
				{{ $t('common.preview') }}
			</ZyroButton>
			<BuilderHeaderPublishButton data-qa="builder-header-btn-publishsite" />
		</div>
	</div>
</template>

<script>
import {
	mapMutations,
	mapGetters,
	mapState,
} from 'vuex';

import BuilderHeaderPublishButton from '@/components/builder-view/headers/buttons/BuilderHeaderPublishButton.vue';
import ZyroLogo from '@/components/ui/ZyroLogo.vue';
import { PREVIEW_ROUTE } from '@/router';
import {
	mapActionsGui,
	UPDATE_HEADER_HEIGHT,
} from '@/store/builder/gui';
import { useRedirects } from '@/use/useRedirects';

import BuilderHeaderSaveButton from './buttons/BuilderHeaderSaveButton.vue';
import BuilderHeaderScreenToggleButton from './buttons/BuilderHeaderScreenToggleButton.vue';

export default {
	components: {
		BuilderHeaderSaveButton,
		BuilderHeaderScreenToggleButton,
		BuilderHeaderPublishButton,
		ZyroLogo,
	},
	setup() {
		const { logoUrl } = useRedirects();

		return {
			logoUrl,
			PREVIEW_ROUTE,
		};
	},
	computed: {
		...mapState('user', ['user']),
		...mapState(['website']),
		...mapGetters('undoRedo', [
			'isUndoDisabled',
			'isRedoDisabled',
		]),
	},
	mounted() {
		this.updateHeaderHeight(this.$refs.builderHeader.clientHeight);
	},
	methods: {
		...mapActionsGui({ updateHeaderHeight: UPDATE_HEADER_HEIGHT }),
		...mapMutations('undoRedo', [
			'undoWebsite',
			'redoWebsite',
		]),
	},
};
</script>

<style scoped lang="scss">
@import "./BuilderHeader";
</style>
